import React from 'react'
import { graphql } from 'gatsby'
//import Transition from '../components/Transition'

function HomePageTemplate({ data }) {
  return (
    <div className="fixed inset-x-0 bottom-0 z-10 px-4 pb-4 sm:inset-0 sm:flex md:block sm:items-center sm:justify-center">
      {/*<Transition show={true}>
        <Transition
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
        */}

      <div className="fixed inset-0 transition-opacity">
        <a className="titePageLink" href="/home">
          <div
            className="absolute inset-0 flex items-center bg-dark-grey bg-opacity-25"
            style={{ backgroundColor: '#ccc' }}
          >
            <div className="relative mx-auto">
              <img
                alt=""
                className="mx-auto"
                style={{ maxHeight: '680px' }}
                src={data.file.childImageSharp.fluid.src}
              />
              <p
                className="max-w-lg pt-4 mx-auto text-center text-sm"
                style={{ color: '#4d6d9a' }}
              >
                (Click anywhere to proceed)
              </p>
            </div>
            <div className="absolute top-0 right-0 pt-4 pr-4">
              <a
                className="text-black hover:text-dark-blue focus:outline-none transition ease-in-out duration-150"
                href="/home/"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </a>
            </div>
          </div>
        </a>
      </div>
    </div>
  )
}

const HomePage = ({ data }) => {
  return <HomePageTemplate data={data} />
}

export default HomePage

export const homeQuery = graphql`
  query HomPage {
    file(relativePath: { eq: "newtitleimage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
